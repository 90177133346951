
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb"

export default defineComponent({
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("盈亏列表", ["用户管理;box_infos", route.params.id.toString()])
    })

    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const tableData = ref([])
    const currentMemberId = ref(0)
    const totalAmount = ref(0)

    const setCurrentMemberId = (id) => {
      currentMemberId.value = id
    }

    const formData = ref({
      nickname: "",
      mobile: "",
    })

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 0,
    })

    let date = new Date()
    let from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
    let to = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)

    const duration = ref([
      from,
      to,
    ])

    const query = () => {
      let params = {
        ...formData.value,
        from: `${duration.value[0].toLocaleDateString()} ${duration.value[0].getHours()}:${duration.value[0].getMinutes()}:${duration.value[0].getSeconds()}`,
        to: `${duration.value[1].toLocaleDateString()} ${duration.value[1].getHours()}:${duration.value[1].getMinutes()}:${duration.value[1].getSeconds()}`,
      }

      store
        .dispatch(Actions.GET_REPORT_DAYS, { user_id: route.params.id, ...params, ...page.value })
        .then(() => {
          tableData.value = store.getters.currentReportDays
          page.value.totalResult = store.getters.currentReportDayCount

          // if (store.getters.currentReportDays.length > 0) {
          //   let sum_add_balance_amount = 0
          //   let sum_pending_amount = 0
          //   let sum_delivering_amount = 0
          //   let sum_delivered_amount = 0
          //   let sum_compounded_amount = 0
          //   let last_balance = 0
          //   let last_membership_point = 0

          //   store.getters.currentReportDays.forEach((i) => {
          //     sum_add_balance_amount += i['add_balance_amount']
          //     sum_pending_amount += i['pending_amount']
          //     sum_delivering_amount += i['delivering_amount']
          //     sum_delivered_amount += i['delivered_amount']
          //     sum_compounded_amount += i['compounded_amount']
          //     last_balance = i['balance']
          //     last_membership_point = i['membership_point']
          //   })

          //   last_membership_point = last_membership_point * 0.1
          //   // totalAmount.value = sum_add_balance_amount - sum_pending_amount - sum_delivering_amount - sum_delivered_amount + sum_compounded_amount - last_balance - last_membership_point
          //   totalAmount.value = sum_add_balance_amount - sum_pending_amount - sum_delivering_amount - sum_delivered_amount - last_balance - last_membership_point
          // }

          console.log(store.getters.currentReportDay)
          totalAmount.value = store.getters.currentReportDay['current_gain']
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getErrors)

          Swal.fire({
            text: store.getters.getErrors[error],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          })
        })
    }

    query();

    const submitEvent = () => {
      query();
    };

    const onPageChange = (e) => {
      page.value.currentPage = e.currentPage;
      query();
    };

    const onReset = () => {
      formData.value = {
        nickname: "",
        mobile: "",
      };
    }

    return {
      tableData,
      formData,
      submitEvent,
      page,
      onPageChange,
      onReset,
      setCurrentMemberId,
      currentMemberId,
      duration,
      totalAmount
    }
  },
})
